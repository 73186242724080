<!-- 
	This is the sign in page, it uses the dashboard layout in: 
	"./layouts/Default.vue" .
 -->

<template>
	<div class="sign-in">
		
		<a-row type="flex" :gutter="[24,24]" justify="space-around" align="middle">

			<!-- Sign In Form Column -->
			<a-col :span="24" :md="12" :lg="{span: 12, offset: 0}" :xl="{span: 6, offset: 2}" class="col-form">
				<h1 class="mb-15">登录</h1>
				<h5 class="font-regular text-muted">输入您的邮箱及密码登入</h5>

				<!-- Sign In Form -->
				<a-form
					id="components-form-demo-normal-login"
					:form="form"
					class="login-form"
					@submit="handleSubmit"
					:hideRequiredMark="true"
				>
					<a-form-item class="mb-10" label="邮箱" :colon="false">
						<a-input 
						v-decorator="[
						'email',
						{ rules: [{ required: true, message: '请输入您的邮箱！' }] },
						]" placeholder="邮箱" />
					</a-form-item>
					<a-form-item class="mb-5" label="密码" :colon="false">
						<a-input
						v-decorator="[
						'password',
						{ rules: [{ required: true, message: '请输入密码' }] },
						]" type="password" placeholder="密码" />
					</a-form-item>
					<a-form-item>
						<a-spin :spinning="loginLoading">
						<a-button type="primary" block html-type="submit" class="login-form-button">
							登录
						</a-button>
					</a-spin>
					</a-form-item>
				</a-form>
				<!-- / Sign In Form -->

				<p class="font-semibold text-muted">没有账户？<router-link to="/sign-up" class="font-bold text-dark">注册</router-link></p>
			</a-col>
			<!-- / Sign In Form Column -->

			<!-- Sign In Image Column -->
			<a-col :span="24" :md="12" :lg="12" :xl="12" class="col-img">
				<img src="images/img-signin.jpg" alt="">
			</a-col>
			<!-- Sign In Image Column -->

		</a-row>
		
	</div>
</template>

<script>
import { notification } from 'ant-design-vue';
	export default ({
		data() {
			return {
				loginLoading:false,
				// Binded model property for "Sign In Form" switch button for "Remember Me" .
				rememberMe: true,
			}
		},
		beforeCreate() {
			// Creates the form and adds to it component's "form" property.
			this.form = this.$form.createForm(this, { name: 'normal_login' });
		},
		methods: {

			// Handles input validation after submission.
			handleSubmit(e) {
				this.loginLoading=true
				e.preventDefault();
				this.form.validateFields((err, values) => {
					if ( !err ) {
						this.$http({
							url:"https://api.wormholecloud.com/api/v1/login",
							method:'post',
							data:JSON.stringify({
								username:values.email,
								password:values.password
							})
						}).then(res=>{
							this.loginLoading=false
							if(res.data.status!=1){
								notification["error"]({
									message: '登录失败',
									description:
									res.data.message,
								});
							}else{

								notification["success"]({
									message: '登录成功',
								});
								//提交token 跳转dashboard
								this.$store.commit("setToken",res.data.data.token)
								this.$store.commit("setInviteCode",res.data.data.invite_code)
								this.$store.commit("setLoginStatus",true)

								this.$router.push('/dashboard')
							}
						}).catch(err=>{
							console.log(err)
							this.loginLoading=false
							notification["error"]({
									message: '登录失败',
									description:
									'API出错',
								});
						})
					}else{
						this.loginLoading=false
					}
				});
			},
		},
	})

</script>

<style lang="scss">
	body {
		background-color: #ffffff;
	}
</style>